import { graphql, navigate, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"
import Layout from '../layouts/Main'

const Wrapper = styled.div`
	position: relative;
	min-height: 600px;
	display: flex;
	align-items: center;
	> :first-child {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: -1;
	}
`

const Content = styled.div`
	background-color: #1B054Dcc;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 580px;
  margin: auto;
  padding: 30px;
  font-size: 24px;
  box-shadow: 8px 8px 0px rgb(255 255 255);
	color: #fff;
	> :first-child {
		font-size: 3rem;
    font-family: 'Rammetto One', cursive;
	}
	> :nth-child(2) {
		margin-bottom: 20px;
	}
	> :nth-child(3) {

	}
`


const NotFoundPage = () => {
  const { file } = useStaticQuery(graphql`
  {
    file(relativePath: { eq: "desert.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
  `)
  return <Layout>
		<Wrapper onClick={() => {
			if (typeof window !== 'undefined') {
				window.history.back()
				return
			}
			navigate('/')
		}}>
			<GatsbyImage image={getImage(file)} alt='' />
			<Content>
				<div>Wrong place!</div>
				<div>This place doesn't exist in the zoo!</div>
				<div>Press anywhere to go back.</div>
			</Content>
		</Wrapper>
  </Layout>
}

export default NotFoundPage
