/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import "@fontsource/libre-baskerville" // Defaults to weight 400 with all styles included.
import "@fontsource/rammetto-one"
import PropTypes from "prop-types"
import * as React from "react"
import styled from "styled-components"
import Footer from "../components/Footer"
import Header from "../components/Header"
import SEO from '../components/SEO'
import BareLayout from "./Bare"

const Content = styled.div`
  > :first-child {
    padding-top: 50px;
  }
`

const Layout = ({ children }) => (
  <BareLayout>
    <SEO />
    <Header />
    <Content>
      { children }
    </Content>
    <Footer />
  </BareLayout>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
